import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8393a93c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card p-5" }
const _hoisted_2 = { m: "4" }
const _hoisted_3 = {
  key: 0,
  class: "card"
}
const _hoisted_4 = {
  key: 0,
  class: "d-flex flex-column align-items-end"
}
const _hoisted_5 = { class: "name-members" }
const _hoisted_6 = {
  style: {"font-size":"12px"},
  class: "name-members"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "d-flex justify-content-end my-3" }
const _hoisted_9 = {
  key: 1,
  class: "d-flex justify-content-center"
}
const _hoisted_10 = {
  class: "d-flex align-items-center",
  style: {"padding":"8px 12px"}
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "d-flex align-items-center content-profile-circle" }
const _hoisted_13 = {
  class: "profile-circle",
  style: {"background":"rgb(128 128 128)"}
}
const _hoisted_14 = { class: "d-flex mx-2 name-members" }
const _hoisted_15 = { class: "name-members" }
const _hoisted_16 = {
  key: 0,
  class: "d-flex flex-column align-items-end"
}
const _hoisted_17 = { class: "name-members" }
const _hoisted_18 = {
  style: {"font-size":"12px"},
  class: "name-members"
}
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "d-flex justify-content-end" }
const _hoisted_21 = { class: "d-flex justify-content-end my-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_search = _resolveComponent("search")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      onTabClick: _ctx.fillUsers,
      type: "border-card"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detailData, (role, index) => {
          return (_openBlock(), _createBlock(_component_el_tab_pane, {
            key: index,
            label: role.name
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table, {
                data: _ctx.pagedTableData(role.members),
                style: {"width":"100%"},
                "row-key": (row) => row._id,
                "expand-row-keys": _ctx.expandRowKeys,
                onExpandChange: _ctx.handleExpandChange
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, { type: "expand" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        (_ctx.user_act.length)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                              _createVNode(_component_el_table, {
                                data: _ctx.dpagedTableData(_ctx.user_act),
                                "cell-style": { background: '#F2F6FF' },
                                style: {"width":"100%"}
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_table_column, {
                                    prop: "asset_name",
                                    label: _ctx.$t('admin.asset')
                                  }, null, 8, ["label"]),
                                  _createVNode(_component_el_table_column, {
                                    prop: "description",
                                    label: _ctx.$t('admin.activity')
                                  }, null, 8, ["label"]),
                                  _createVNode(_component_el_table_column, {
                                    align: "right",
                                    sortable: "",
                                    prop: "created_at",
                                    label: _ctx.$t('admin.last_activity')
                                  }, {
                                    default: _withCtx((scope) => [
                                      (scope.row.created_at)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.timeLocal(scope.row.created_at)), 1),
                                            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.hourLocal(scope.row.created_at)), 1)
                                          ]))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_7))
                                    ]),
                                    _: 1
                                  }, 8, ["label"])
                                ]),
                                _: 1
                              }, 8, ["data"]),
                              _createElementVNode("div", _hoisted_8, [
                                (_ctx.user_act.length)
                                  ? (_openBlock(), _createBlock(_component_el_pagination, {
                                      key: 0,
                                      layout: "prev, pager, next",
                                      background: "",
                                      total: _ctx.user_act.length,
                                      onCurrentChange: _ctx.dsetPage
                                    }, null, 8, ["total", "onCurrentChange"]))
                                  : _createCommentVNode("", true)
                              ])
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createVNode(_component_inline_svg, { src: _ctx.empty_icon }, null, 8, ["src"])
                            ]))
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "name",
                    sortable: "",
                    label: _ctx.$t('admin.name')
                  }, {
                    default: _withCtx((scope) => [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", {
                          onClick: ($event: any) => (_ctx.getUserActivity(scope.row._id))
                        }, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, _toDisplayString(scope.row.name.charAt(0)), 1),
                            _createElementVNode("span", _hoisted_14, _toDisplayString(scope.row.name), 1)
                          ])
                        ], 8, _hoisted_11)
                      ])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    sortable: "",
                    prop: "_id",
                    label: "NIP"
                  }, {
                    default: _withCtx((scope) => [
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.nipMembers(scope.row._id)?.nip), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    align: "right",
                    sortable: "",
                    prop: "_id",
                    label: _ctx.$t('admin.last_activity')
                  }, {
                    default: _withCtx((scope) => [
                      (_ctx.lastActivity(scope.row._id)?.last_activity)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                            _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.timeLocal(_ctx.lastActivity(scope.row._id)?.last_activity)), 1),
                            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.hourLocal(_ctx.lastActivity(scope.row._id)?.last_activity)), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_19, " - "))
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_table_column, { align: "right" }, {
                    header: _withCtx(() => [
                      _createElementVNode("div", _hoisted_20, [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.search,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                          class: "w-100 m-2",
                          placeholder: _ctx.t('admin.search_name')
                        }, {
                          prefix: _withCtx(() => [
                            _createVNode(_component_el_icon, { class: "el-input__icon" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_search)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue", "placeholder"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["data", "row-key", "expand-row-keys", "onExpandChange"]),
              _createElementVNode("div", _hoisted_21, [
                (_ctx.search === '')
                  ? (_openBlock(), _createBlock(_component_el_pagination, {
                      key: 0,
                      layout: "prev, pager, next",
                      background: "",
                      total: role.members.length,
                      onCurrentChange: _ctx.setPage
                    }, null, 8, ["total", "onCurrentChange"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1032, ["label"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["onTabClick"])
  ]))
}