
import { defineComponent, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { ActionsAdminValidasi } from "@/store/enums/StoreAdminValidasiEnums";
import imagesAsset from "@/assets/ts/images.js";
import { useI18n } from 'vue-i18n';
import moment from "moment";

export default defineComponent({
  name: "c-tab-history",
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const detailData = computed(() => store.getters.roleData);
    const empty_icon = imagesAsset.empty;

    const timeLocal = (tm) => {
      return moment.utc(tm).local().format("DD MMM YYYY")
    }

    const hourLocal = (tm) => {
      return moment.utc(tm).local().format("HH:mm")
    }

    const getListMember = computed(() => {
      return store.getters.getUsers;
    });

    const gettingLastActivity = computed(() => {
      return store.getters.gettingLastActivity;
    });

    const tableRowClassName = computed(() => {
      return "bg-el-table";
    });

    const lastActivity = (val) => {
      const temp = gettingLastActivity.value.filter(function (x) {
        if (x.user_id === val) {
          return x.user_id === val;
        } else {
          return false;
        }
      });
      return temp[0];
    };

    const nipMembers = (val) => {
      const temp = getListMember.value.filter(function (x) {
        if (x._id === val) {
          return x._id === val;
        } else {
          return false;
        }
      });
      return temp[0];
    };

    const user_act = ref([]) as any;

    const getUserActivity = async (id) => {
      user_act.value = []
      const payload = {
        id: id,
        showAll: 0
      }
      await store
        .dispatch(ActionsAdminValidasi.GET_DETAIL_USER_ACTIVITY, payload)
        .then((response) => {
          if (response.data.length) {
            user_act.value = response.data;
          }
        });
    };

    const search = ref("");

    const expandRowKeys = ref([]) as any;

    const handleExpandChange = (row, expandedRows) => {
      const id = row._id;
      getUserActivity(id);
      const lastId = expandRowKeys.value[0];
      expandRowKeys.value = id === lastId ? [] : [id];
    };

    const page = ref(1);
    const pageSize = ref(5);

    const pagedTableData = (val) => {
      const filteredArr = val.filter(item => item.name.toLowerCase().includes(search.value.toLowerCase()));
      return filteredArr.slice(
        pageSize.value * page.value - pageSize.value,
        pageSize.value * page.value
      );
    };

    const setPage = (val) => {
      page.value = val;
    };

    // pagination user detail

    const dpage = ref(1);
    const dpageSize = ref(10);

    const dpagedTableData = (val) => {
      return val.slice(
        dpageSize.value * dpage.value - dpageSize.value,
        dpageSize.value * dpage.value
      );
    };

    const dsetPage = (val) => {
      dpage.value = val;
    };

    const fillUsers = (e) => {
      page.value = 1
      dpage.value = 1
      search.value = ''
    }

    return {
      t,
      timeLocal,
      hourLocal,
      pagedTableData,
      setPage,
      dpagedTableData,
      dsetPage,
      detailData,
      search,
      user_act,
      nipMembers,
      getUserActivity,
      lastActivity,
      handleExpandChange,
      expandRowKeys,
      empty_icon,
      tableRowClassName,
      fillUsers
    };
  },
});
