
import { defineComponent, onMounted, nextTick, watch, ref } from "vue";
import { useI18n } from "vue-i18n";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkAccess } from "@/core/helpers/helps";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import LeafletMap from "@/components/leaflet/Leaflet.vue";

export default defineComponent({
  name: "monitoring-coordinate",
  components: { LeafletMap },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    // pagination variable declare
    const currentPage = ref(1);
    const pageSize2 = ref(10);
    const small = ref(false);
    const background = ref(false);
    const disabled = ref(false);
    // end

    const loading = ref(false);
    const temps = ref([]);
    const total = ref(0);

    const renderComponent = ref(true)
    const forceRenderer = () => {
      renderComponent.value = false
      nextTick(() => {
        renderComponent.value = true
      })
    }

    const fetchData = async (letParams: any) => {
      loading.value = true;
      await store
        .dispatch(Actions.GET_LIST_MONITORING_COORDINATE, letParams)
        .then((data) => {
          if (data) {
            total.value = data.meta.totalRecord;
            temps.value = data.data;
            loading.value = false;
          }
        })
        .catch((error) => {
          loading.value = false;
        });
    };

    const handleClose = () => {
      dialogVisible.value = false;
    };

    const handleSizeChange = (val: number) => {
      fetchData({
        limit: val,
      });
    };
    const handleCurrentChange = (val: number) => {
      router.push(`/admin/coordinate?p=${val}`);
      fetchData({
        page: val,
      });
    };

    const truePolygon = ref([])
    const truePolyline = ref([])

    const long = ref("");
    const lat = ref("");
    const polygon = ref([]);
    const polyline = ref([])


    const checkPolygon = (val) => {
      let found = false
        for (var i = 0; i < val?.polygon.length; i++) {
          if (!Array.isArray(val?.polygon[i])) {
            found = true
            break;
          }
        }
        if (val?.polygon.length > 0) {
          return truePolygon.value = found ? [val?.polygon] : val?.polygon
        } else {
          return truePolygon.value = []
        }
    }

    const checkPolyline = (val) => {
      let foundPolyline = false
      for (var x = 0; x < val?.polyline.length; x++) {
        if (!Array.isArray(val?.polyline[x])) {
          foundPolyline = true
          break;
        }
      }
      if (val?.polyline.length > 0) {
        return truePolyline.value = foundPolyline ? [val?.polyline] : val?.polyline
      } else {
        return truePolyline.value = []
      }
    }

    const dialogVisible = ref(false);

    const form = ref();

    const openMap = (val) => {
      forceRenderer()

      lat.value = val.coordinate.lat;
      long.value = val.coordinate.long;
      polygon.value = checkPolygon(val);
      polyline.value = checkPolyline(val)
      dialogVisible.value = true;

      setTimeout(function() { window.dispatchEvent(new Event('resize')) }, 250);
    };

    setCurrentPageBreadcrumbs("admin input", [
      { title: "admin", path: "/admin" },
      {
        title: "admin.monitoringCoordinate",
      },
    ]);

    onMounted(async () => {
      if (!checkAccess("admin")) {
        router.push({
          name: "404",
        });
      }

      const urlParams = new URLSearchParams(window.location.search);
      const pageValue = urlParams.get("p");

      if (pageValue !== null) {
        currentPage.value = Number(pageValue);
      }

      await fetchData({
        page: currentPage.value,
        limit: pageSize2.value,
      });
    });

    return {
      t,
      loading,
      temps,
      currentPage,
      pageSize2,
      small,
      background,
      disabled,
      total,
      dialogVisible,
      long,
      lat,
      polygon,
      polyline,
      form,
      renderComponent,
      handleClose,
      openMap,
      handleSizeChange,
      handleCurrentChange,
      checkPolygon,
      checkPolyline
    };
  },
});
