import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column position-relative" }
const _hoisted_2 = { class: "text-muted fs-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_tabAdminHistory = _resolveComponent("tabAdminHistory")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('admin.adminHistory')), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('admin.description')), 1),
    _createElementVNode("div", null, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 0,
            rows: 5,
            animated: ""
          }))
        : (_openBlock(), _createBlock(_component_tabAdminHistory, { key: 1 }))
    ])
  ]))
}