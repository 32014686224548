import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-93ca9618"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column position-relative" }
const _hoisted_2 = { class: "text-muted fs-6" }
const _hoisted_3 = { style: {"font-size":"12px","font-weight":"400"} }
const _hoisted_4 = { class: "name-user" }
const _hoisted_5 = { style: {"font-size":"12px","font-weight":"600"} }
const _hoisted_6 = {
  key: 1,
  class: "mt-4 button_pagination mb-2 w-100"
}
const _hoisted_7 = { class: "mt-3 d-flex align-items-center justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('admin.historyActivity')), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('admin.descriptionHistory')), 1),
    _createElementVNode("div", null, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 0,
            rows: 5,
            animated: ""
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_table, {
        data: _ctx.temps,
        "default-sort": { prop: 'date', order: 'descending' },
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "user_id",
            label: _ctx.$t('admin.user')
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getUserById(scope.row.user_id).role.name), 1),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getUserById(scope.row.user_id).name), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getUserById(scope.row.user_id).nip), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "asset_name",
            label: _ctx.$t('admin.asset')
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "action",
            label: _ctx.$t('admin.activity')
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "description",
            label: _ctx.$t('admin.subactivity')
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "created_at",
            label: _ctx.$t('admin.date'),
            sortable: "",
            width: "180"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", null, _toDisplayString(_ctx.timeLocal(scope.row.created_at)) + ", ", 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.hourLocal(scope.row.created_at)), 1)
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["data"]),
      (_ctx.temps.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_el_pagination, {
                "current-page": _ctx.currentPage,
                "onUpdate:current-page": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPage) = $event)),
                "page-size": _ctx.pageSize2,
                "onUpdate:page-size": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageSize2) = $event)),
                "page-sizes": [10, 50, 100],
                "pager-count": 5,
                disabled: _ctx.disabled,
                background: "",
                layout: "prev, pager, next",
                total: _ctx.total,
                onSizeChange: _ctx.handleSizeChange,
                onCurrentChange: _ctx.handleCurrentChange
              }, null, 8, ["current-page", "page-size", "disabled", "total", "onSizeChange", "onCurrentChange"])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}