
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { computed, onMounted, ref } from "vue";
import { checkPermission, checkPermissionUpdate } from "@/core/helpers/helps";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import TENANT_SETTING_MODEL from "@/core/data/tenantSettingModel";
import { useI18n } from "vue-i18n";
// set tutor
import * as storeTutorial from "@/store/enums/StoreTutorialEnums";
import { Tutorial } from "@/store/enums/interface_option/tutorial";

export default {
  name: "general-setting",
  setup() {
    const { t } = useI18n();
    
    const store = useStore();
    const themeSelected = computed({
      get: () => store.getters.getFormThemeVal,
      set: (val) => store.dispatch(Actions.UPDATE_GS_FORM_THEME, val),
    });
    const langSelected = computed({
      get: () => store.getters.getFormLangVal,
      set: (val) => store.dispatch(Actions.UPDATE_GS_FORM_LANG, val),
    });
    const themes = ref([
      {
        id: "951b7af8-bf7c-4427-81e7-2598c192ce03",
        code: "5XYXKX",
        name: "telkom",
        descriptions: "Theme Telkom",
        color: "#b31e72",
        deleted_at: null,
        created_at: "2021-12-14T03:29:57.000000Z",
        updated_at: "2021-12-14T03:29:57.000000Z"
      },
      {
        id: "951b7ac5-0f89-4652-b638-47da6ba05faf",
        code: "8RMAP2",
        name: "default",
        descriptions: "Theme Default",
        color: "#009ef7",
        deleted_at: null,
        created_at: "2021-12-14T03:29:23.000000Z",
        updated_at: "2021-12-14T03:31:49.000000Z"
      }
    ]);
    const locales = ref([
      {
        id: "951b789e-90a6-4f9d-987a-0c7bacf78892",
        code: "7TYJEB",
        lang: "en",
        name: "ENGLISH",
        descriptions: "ENGLISH",
        icon: "us",
        deleted_at: null,
        created_at: "2021-12-14T03:23:23.000000Z",
        updated_at: "2022-01-13T19:05:36.000000Z"
      },
      {
        id: "951b788d-95c8-431a-8d81-c5a2881cf8ab",
        code: "4N6AAZ",
        lang: "id",
        name: "INDONESIA",
        descriptions: "INDONESIA",
        icon: "id",
        deleted_at: null,
        created_at: "2021-12-14T03:23:12.000000Z",
        updated_at: "2022-01-13T19:06:27.000000Z"
      }
    ]);
    onMounted(async () => {
      await store.dispatch(Actions.UPDATE_GS_FORM_THEME, localStorage.getItem('mode'))
      await store.dispatch(Actions.UPDATE_GS_FORM_LANG, localStorage.getItem('locale'))

      setCurrentPageBreadcrumbs(("generalSetting"), [
          {title:("common.setting"), path: '/setting'},
          {title:("organization"), path: '/setting/organization/general-setting'},]);

      // await store
      //   .dispatch(Actions.GET_LANGUAGE_LIST)
      //   .then((result) => (locales.value = result.data.data))
      //   .catch((error) => console.log("error", error));

      // store.commit(storeTutorial.Mutations.FILTER_STEP, {
      //   name: "organization/general-setting",
      //   bool: false,
      // } as Tutorial.localStorage);

      // await store
      //   .dispatch(Actions.GET_THEME_LIST)
      //   .then(
      //     (result) => (
      //       (themes.value = result.data.data),
      //       store.commit(storeTutorial.Mutations.FILTER_STEP, {
      //         name: "organization/general-setting",
      //         bool: true,
      //       } as Tutorial.localStorage)
      //     )
      //   )
      //   .catch((error) => console.log("error", error));

      // if (currentUser.value && currentUser.value.id) {
      //   setInitialTheme();
      //   setInitialLanguage();
      // }


    });
    const currentUser = computed(() => store.getters.currentUser);
    function setInitialTheme() {
      if (
        themes.value.length > 0 &&
        currentUser.value.tenant_setting[TENANT_SETTING_MODEL.theme]
      ) {
        const findSelected = currentUser.value.tenant_setting[
          TENANT_SETTING_MODEL.theme
        ].find((setting) => setting.is_default);
        if (findSelected) themeSelected.value = findSelected.relation_code;
      }
    }
    function setInitialLanguage() {
      if (
        locales.value.length > 0 &&
        currentUser.value.tenant_setting[TENANT_SETTING_MODEL.lang]
      ) {
        const findSelected = currentUser.value.tenant_setting[
          TENANT_SETTING_MODEL.lang
        ].find((setting) => setting.is_default);
        if (findSelected) langSelected.value = findSelected.relation_code;
      }
    }

    const data = JSON.parse(window.localStorage.getItem("role") as any)
    const activeItems = data?.page_permissions

    return {
      themeSelected,
      langSelected,
      locales,
      themes,
      checkPermission,
      checkPermissionUpdate,
      activeItems
    };
  },
};
