import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-muted fs-6" }
const _hoisted_2 = { class: "card p-5" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  class: "text-decoration-underline",
  style: {"cursor":"pointer"}
}
const _hoisted_5 = {
  key: 0,
  class: "mt-4 button_pagination mb-2 w-100"
}
const _hoisted_6 = { class: "mt-3 d-flex align-items-center justify-content-end" }
const _hoisted_7 = {
  class: "my-header",
  style: {"display":"flex","justify-content":"end","margin-top":"-45px","margin-bottom":"40px"}
}
const _hoisted_8 = { class: "mapouter" }
const _hoisted_9 = { class: "gmap_canvas" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_LeafletMap = _resolveComponent("LeafletMap")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t("admin.monitoringCoordinate")), 1),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("admin.descCoordinate")), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_table, {
        data: _ctx.temps,
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "name",
            label: _ctx.$t('admin.name')
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "channel_name",
            label: "BUMN"
          }),
          _createVNode(_component_el_table_column, {
            prop: "province",
            label: _ctx.$t('admin.location')
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "coordinate",
            label: _ctx.$t('admin.point'),
            width: "400"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("a", {
                onClick: ($event: any) => (_ctx.openMap(scope.row))
              }, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(scope.row.coordinate.lat + "," + scope.row.coordinate.long), 1)
              ], 8, _hoisted_3)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "total_polyline",
            label: "Polyline"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("span", null, _toDisplayString(_ctx.checkPolyline(scope.row).length), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "total_polygon",
            label: "Polygon"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("span", null, _toDisplayString(_ctx.checkPolygon(scope.row).length), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"]),
      (_ctx.temps.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_el_pagination, {
                "current-page": _ctx.currentPage,
                "onUpdate:current-page": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPage) = $event)),
                "page-size": _ctx.pageSize2,
                "onUpdate:page-size": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageSize2) = $event)),
                "page-sizes": [10, 50, 100],
                "pager-count": 5,
                disabled: _ctx.disabled,
                background: "",
                layout: "prev, pager, next",
                total: _ctx.total,
                onSizeChange: _ctx.handleSizeChange,
                onCurrentChange: _ctx.handleCurrentChange
              }, null, 8, ["current-page", "page-size", "disabled", "total", "onSizeChange", "onCurrentChange"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      "show-close": false,
      "close-on-click-modal": false,
      width: "50%"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", {
            style: {"cursor":"pointer"},
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
          }, " X ")
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (_ctx.renderComponent)
              ? (_openBlock(), _createBlock(_component_LeafletMap, {
                  key: 0,
                  lat: _ctx.lat,
                  lng: _ctx.long,
                  polygon: _ctx.polygon,
                  polyline: _ctx.polyline,
                  search: false,
                  view: true,
                  height: 50,
                  heightType: 'vh',
                  zoom: 15,
                  positionType: 'relative',
                  ref: "form"
                }, null, 8, ["lat", "lng", "polygon", "polyline"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}