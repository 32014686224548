
import { defineComponent, onMounted, computed, watch, ref } from "vue";
import { useI18n } from 'vue-i18n';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkAccess } from "@/core/helpers/helps";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsAdminValidasi } from '@/store/enums/StoreAdminValidasiEnums';
import moment from "moment";

export default defineComponent({
  name: "history-activity",
  components: {
  },
  setup() {
    const { t } = useI18n();
    const timeLocal = (tm) => {
      return moment.utc(tm).local().format("DD MMM YYYY")
    }
    const hourLocal = (tm) => {
      return moment.utc(tm).local().format("HH:mm")
    }
    onMounted(async () => {
      if (!checkAccess('admin')) {
        router.push({
          name: '404', 
        });
      }
      
      store.dispatch(Actions.GET_USER_LIST, store.getters.usersFilter)

      const urlParams = new URLSearchParams(window.location.search);
      const pageValue = urlParams.get("p");

      if (pageValue !== null) {
        currentPage.value = Number(pageValue);
      }

      store.dispatch(ActionsAdminValidasi.GET_LAST_ACTIVITY)
      await fetchData({
        page: currentPage.value,
        limit: pageSize2.value,
        showAll: 1
      });
    });

    const loading = ref(false)

    const currentPage = ref(1);
    const pageSize2 = ref(10);
    const disabled = ref(false);

    const temps = ref([]);
    const total = ref(0);

    const getListMember = computed(() => {
      return store.getters.getUsers;
    });

    const getUserById = (payload: any) => {
      if (getListMember.value.length !== 1) {
        let appr = getListMember.value.filter((el) => el._id === payload);
        if (appr[0]?.name !== undefined) {
          return appr[0];
        } else {
          return 'Undefined'
        }
      } else {
        return "-";
      }
    };

    const fetchData = async (letParams: any) => {
      loading.value = true;
      await store.dispatch(ActionsAdminValidasi.GET_DETAIL_USER_ACTIVITY, letParams)
        .then((data) => {
          if (data) {
            total.value = data.meta.total_data;
            temps.value = data.data;
            loading.value = false;
          }
        })
        .catch((error) => {
          loading.value = false;
        });
    };

    const handleSizeChange = (val: number) => {
      fetchData({
        limit: val,
        showAll: 1
      });
    };
    const handleCurrentChange = (val: number) => {
      router.push(`/admin/history-activity?p=${val}`);
      fetchData({
        page: val,
        showAll: 1
      });
    };

    const router = useRouter();
    const store = useStore();

    setCurrentPageBreadcrumbs("admin.historyActivity", [
      { title: "admin", path: "/admin" },
    ]);

    return {
      t,
      loading,
      temps,
      getUserById,
      getListMember,
      timeLocal,
      hourLocal,
      currentPage,
      pageSize2,
      disabled,
      total,
      handleSizeChange,
      handleCurrentChange
    };
  },
});
