
import { defineComponent, onMounted, computed, watch, ref } from "vue";
import { useI18n } from 'vue-i18n';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkAccess } from "@/core/helpers/helps";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsAdminValidasi } from '@/store/enums/StoreAdminValidasiEnums';
import tabAdminHistory from "@/components/tab/AdminHistory.vue";

export default defineComponent({
  name: "history-admin",
  components: {
    tabAdminHistory,
  },
  setup() {
    const { t } = useI18n();
    onMounted(async () => {
      if (!checkAccess('admin')) {
        router.push({
          name: '404', 
        });
      }
      store.dispatch(Actions.UPDATE_USERS_ITEMS_PER_PAGE, 200)
      store.dispatch(ActionsAdminValidasi.GET_LAST_ACTIVITY)
      await fetchData();
    });

    const loading = ref(false)

    async function fetchData() {
      loading.value = true
      store.dispatch(Actions.GET_USER_LIST, store.getters.usersFilter)
      await store.dispatch(Actions.GET_ROLE_LIST)
      .then((data) => {
        if (data) {
          loading.value = false
        }
      })
      .catch((error) => {
        loading.value = false
        alert(error);
      });
    }
    const router = useRouter();
    const store = useStore();

    setCurrentPageBreadcrumbs("admin input", [
      { title: "admin", path: "/admin" },
      {
        title: ('admin.adminHistory'),
        //   path: '/setting/organization/general-setting',
      },
    ]);
    return {
      t,
      loading
    };
  },
});
